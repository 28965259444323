import * as React from "react";

const ArrowBottomRight = (props) => {
  return (
    <svg
      viewBox="0 0 187 186"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(93.500000, 93.000000) scale(-1, 1) translate(-93.500000, -93.000000) "
          fill="currentColor"
          fillRule="nonzero"
        >
          <polygon points="173.63 0.13 18.94 154.81 18.94 33.62 0.71 33.62 0.71 167.7 0.71 185.35 0.71 185.93 152.43 185.93 152.43 167.7 31.83 167.7 186.52 13.02"></polygon>
        </g>
      </g>
    </svg>
  );
};

export default ArrowBottomRight;
