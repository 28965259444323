const phrases = [
    //0
    {
        pl: 'Zasady gry',
        en: 'Game rules'
    },
    //1
    {
        pl: 'Formularz',
        en: 'Form'
    }
]

export default phrases
