import * as React from "react";
import { Link } from "gatsby";
import { css, useTheme } from "@emotion/react";

import Seo from "../Seo";
import Footer from "../Footer";
import ArrowBottomRight from "../ArrowBottomRight";
import ArrowRight from "../ArrowRight";
import Navigation from "../Navigation";
import phrases from "../constants/indexPhrases";
import { getDevice } from "../utils";

const getIconSize = (device) => {
  if (device === "mobile") {
    return "30px";
  }
  if (device === "tablet") {
    return "60px";
  }
  if (device === "smallDesktop") {
    return "90px";
  }
  return "130px";
};

const constainerStyle = (theme) => css`
  background-color: ${theme.colors.red};
  min-height: 100vh;
`;

const mainMenuStyle = (theme) => css`
  ${theme.emotionTypography.big};
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  min-height: 90vh;

  a {
    display: flex;
    color: ${theme.colors.blackText};
    text-decoration: none;
    border-bottom: 1px solid ${theme.colors.black};
    padding: 30px 0;
    justify-content: space-between;
    align-items: baseline;

    &:hover {
      color: ${theme.colors.whiteText};
    }
  }

  li:last-child a {
    border-bottom: none;
  }
`;

const topArrowStyle = css`
  transform: rotate(-90deg);
`;

// markup
const IndexPage = ({ pageContext }) => {
  const language = pageContext?.language || "pl";
  const otherLanguageUrl = language === "pl" ? "/en" : "/";
  const theme = useTheme();
  const [iconSize, setIconSize] = React.useState("40px");
  React.useEffect(() => {
    setIconSize(getIconSize(getDevice()));
  }, []);

  return (
    <div css={constainerStyle}>
      <Seo language={language} title={language === "pl" ? "Główna" : "Home"} />
      <Navigation
        hideBack
        language={language}
        languageUrl={otherLanguageUrl}
        foreground={theme.colors.blackText}
        background={theme.colors.red}
      />
      <nav>
        <ul css={mainMenuStyle}>
          <li>
            <Link to="/glass-go">
              <span>
                GlassGo<sup>2</sup>
              </span>
              <ArrowBottomRight
                css={topArrowStyle}
                height={iconSize}
                aria-hidden="true"
              />
            </Link>
          </li>
          <li>
            <Link to="/zasady-gry">
              <span>{phrases[0][language]}</span>
              <ArrowRight height={iconSize} aria-hidden="true" />
            </Link>
          </li>
          <li>
            <Link to="/formularz">
              <span>{phrases[1][language]}</span>{" "}
              <ArrowBottomRight height={iconSize} aria-hidden="true" />
            </Link>
          </li>
        </ul>
      </nav>
      <Footer language={language} addSecondLine />
    </div>
  );
};

export default IndexPage;
